import Base = require("Everlaw/Base");
import ContextElement = require("Everlaw/Context/UI/Element");
import ContextTableBase = require("Everlaw/Context/UI/TableBase");
import Dom = require("Everlaw/Dom");
import Table = require("Everlaw/Table");

/**
 * A Table for when in a non Everlaw context.
 */
const ContextTable = new (class<
    OBJ extends Base.Object,
    DATA extends Table.RowData,
> extends ContextElement<typeof Table, ContextTable.Params<OBJ, DATA>> {
    getBase() {
        return Table;
    }
    getContextParams(params: ContextTable.Params<OBJ, DATA>) {
        return {
            updateArgs: (args) => [ContextTableBase.updateTableParams(args[0], params)],
        };
    }
})();

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module ContextTable {
    export interface Params<OBJ extends Base.Object, DATA extends Table.RowData> {
        /**
         * Order of the table colums by id, including the default and new columns. Ids of default
         * columns missing in the list will be omitted from the table.
         */
        columnOrder?: string[];
        /** Cells to be added to the table. */
        newCells?: Table.CellCallback<OBJ, DATA>[];
        /** Headers to be added to the table. */
        newHeaders?: Dom.Content[];
        /** Styling to be applied to the new columns. */
        newColumns?: any[];
        /** New column ids. */
        newIds?: string[];
        /** Omits the onClick attribute. */
        omitOnClick?: boolean;
        /** Updates previous column styling by column id. */
        previousColumns?: { [id: string]: any };
        /** Replace cells by column id. */
        replaceCells?: { [id: string]: Table.CellCallback<OBJ, DATA> };
    }
}

export = ContextTable;
